/*
 * Crosslink (eg, phoenix.md)
 */

.MarkdownBody.MarkdownBody {
  img {
    max-width: 100%;
  }
}

.MarkdownBody.MarkdownBody p.-crosslink {
  & > a {
    display: block;
    text-decoration: none;
    color: $primary;
    border-bottom: 0;
    box-shadow: none;
    margin: -16px;
    padding: 16px;
  }

  & > a:visited {
    color: $primary;
  }

  // & > a::before {
  //   content: "";
  //   @include ion-md-arrow-forward(16px, white);
  //   margin-right: 16px;
  //   width: 32px;
  //   height: 32px;
  //   line-height: 32px;
  //   border-radius: 50%;
  // }

  & > a,
  & > a:visited {
    &::before {
      background-color: $primary;
      color: white;
    }
  }

  & > a:hover,
  & > a:focus {
    & {
      color: $primary-dark;
    }

    &::before {
      background-color: $primary-dark;
    }
  }
}
