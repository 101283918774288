/*
 * h2 section
 */

/* Hide the first h2 heading */
.h2-section {
  &:first-child:not(.-no-hide) > h2 {
    display: none;
  }

  @media (min-width: 481px) {
    & + & {
      margin-top: 48px;
    }
  }

  /* Hide anchors until mouseover */
  & > .anchor {
    opacity: 0;
  }

  &:hover > .anchor {
    opacity: 1;
  }
}
