@import "../styles/variable";

.brand {
  margin: 1.5em 0 0;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  color: $grey-light;
  line-height: 3.5rem;
  font-size: 3rem;
  font-family: "Patua One";
  position: relative;
  & > a {
    color: $grey;
    transition: color 0.4s ease-in-out;
    // & > .home {
    //   color: $grey-light;
    //   font-size: 16px;
    //   position: absolute;
    //   top: 10px;
    //   left: -22px;
    //   opacity: 0;
    //   transition: opacity 0.4s ease-in-out;
    // }
    // &:hover {
    //   & > .home {
    //     opacity: 1;
    //   }
    // }
  }

  .toggle {
    // font-size: 2.3rem;
    // position: relative;
    // bottom: 2px;
    cursor: pointer;

    display: inline-block;
    width: 38px;
    height: 38px;
    background: url(../images/logo-b.svg) center/contain no-repeat;
    position: relative;
    top: 4px;
    left: -2px;
  }
}

.subtitle {
  min-height: 2.5rem;
  cursor: pointer;
  .text {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    line-height: 2.5rem;
    font-size: 2rem;
    font-family: "Kreon";
    white-space: nowrap;
    & > .adjective {
      color: $primary;
      @include underline($primary);
      &:hover {
        @include underline-hover();
      }
    }
  }
}

// @media (prefers-color-scheme: dark) {
//   .brand {
//     color: invert(rgba(0, 0, 0, .40));
//     & > span {
//       color: invert(rgba(0, 0, 0, .80));
//     }
//   }
//   .subtitle {
//     .text {
//       color: invert(rgba(0, 0, 0, .20));
//       & > .adjective {
//         color: invert($primary);
//       }
//     }
//   }
// }
