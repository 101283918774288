
.loaderSvg {
  width: 0;
  height: 0;
}

.goo1, .goo2 {
  filter: url(#goo);
}

.container {
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  div {
    // background: #b3bbc3;
    // background: #FA7C91;
    background: #efefef;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &.gray {
    div {
      background: #e2e4e6;
    }
  }
}

.goo1 {
  min-width: 150px;
  min-height: 150px;
  position: relative;
  & > div {
    width: 15px;
    height: 15px;
    position: absolute;
    top:50%;
    left:50%;
    transform-origin: -50% -50%;
    animation: circle 2s infinite cubic-bezier(.57, 0, .52, 1);
  }
  & > div:nth-child(1) {
    animation-delay: .2s;
  }
  & > div:nth-child(2) {
    animation-delay: .4s;
  }
  & > div:nth-child(3) {
    animation-delay: .6s;
  }
  & > div:nth-child(4) {
    animation-delay: 0.8s;
  }
  & > div:nth-child(5) {
    animation-delay: 1s
  }
}

.goo2 {
  width: 150px;
  height: 100px;
  & > div {
    transform: translateX(0);
    animation: sideways 2s infinite ease;
  }
  & > div:nth-child(1) {
    animation-delay: .2s;
  }
  & > div:nth-child(2) {
    animation-delay: .4s;
  }
  & > div:nth-child(3) {
    animation-delay: .6s;
  }
  & > div:nth-child(4) {
    animation-delay: 0.8s;
  }
  & > div:nth-child(5) {
    animation-delay: 1s
  }
}

@keyframes circle {
  50% {
      width: 25px;
      height: 25px;
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes sideways {
  33% {
      transform: translateX(50px);
  }
  66% {
      transform: translateX(-50px);
  }
}