@import "../styles/variable";

.password {
  margin: 5px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .forms {
    display: flex;
    align-items: center;
  }
  .passwordInput {
    margin-top: 10px;
    width: 100%;
    input {
      margin-top: 10px;
    }
  }
}
