@import "../styles/variable";

.devdocs {
  @media screen and (min-width: 769px), print {
    height: calc(100vh - 58px);
  }
  margin-bottom: 0 !important;
  background-color: $white;
  & > :global(.column) {
    overflow-y: auto;
    padding: 0;
  }
}

.typegroup {
  font-family: $font-sans-doc;
  .typename {
    @include ms-respond(font-size, 1);
    cursor: pointer;
    &:hover {
      background-color: $white-duck;
      color: $primary;
    }
    display: flex;
    align-items: center;
    svg.icon {
      margin: 0 8px;
      transition: transform 0.25s ease;
      color: $grey-lighter;
    }
  }
  ul.childrens {
    display: none;
    li {
      position: relative;
      height: 22.4px; // Caused a.item overflow: hidden
      &:hover {
        background-color: $white-duck;
        a {
          color: $primary;
        }
      }
    }
    a.item {
      color: $grey-dark;
      display: inline-block;
      padding-left: 19px;
      @include stretched-link();

      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-left: 6px solid transparent;
      &.current {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  &.expanding {
    color: $primary;
    svg.icon {
      color: $primary;
      transform: rotate(90deg);
    }
    .childrens {
      display: block;
    }
  }
}

.searchItems {
  padding-bottom: 10px;
  a.item {
    display: block;
    padding: 5px 10px 5px 38px;
    @include ms-respond(font-size, 1);
    &.selected {
      background-color: $white-terer;
    }
    .keyname {
      font-weight: 700;
      color: $grey;
      margin-right: 5px;
    }
    .typename {
      color: $grey2;
    }
    :global(.highlight) {
      font-weight: 700;
      // color: $black-bis;
    }
  }
}

.docContainer {
  :global(p._attribution-p) {
    position: absolute;
    top: 1px;
    right: 0;
    overflow: hidden;
    width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0;
  }
}

div.currentMeta {
  font-family: $font-sans-doc;
  margin-left: 24px;
  .metaName {
    @include ms-respond(font-size, 1);
    text-transform: uppercase;
    font-weight: bold;
  }
  .metaVersion {
    color: #7a7a7a;
    font-size: 0.75em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
