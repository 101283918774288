@import "../styles/variable";

.grid {
  padding: 15px;
  font-family: $font-mono;
  :global(p.title) {
    margin-bottom: 0.5rem;
  }
  :global(.tile.is-parent) {
    border-radius: 6px;
    &:hover {
      background-color: $white-duck;
    }
  }
}

.collection {
  a {
    margin-right: 15px;
    color: $dh-text;
    @include underline($dh-text);
    &.elite {
      color: $primary-dark;
    }
    &:hover {
      @include underline-hover(1px, 2px);
    }
    svg {
      margin-left: 4px;
    }
  }
}
