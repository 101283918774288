@import "../styles/variable";

.cheatsheets {
  opacity: 0.4;
  &.loaddone {
    opacity: 1;
    transition: opacity 100ms linear 100ms;
  }
}

p.devhints {
  margin: 0 20px 1rem;
  a {
    background: url(../../public/keys/devhints.png) left center/contain no-repeat;
    padding-left: 20px;
    font-size: 12px;
  }
}
