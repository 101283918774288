/*
 * MarkdownBody context
 */

.MarkdownBody h2 {
  @include heading-style;
  @include ms-respond(font-size, 3);
  line-height: 1.2;
  font-weight: 200;
  margin-top: 0;

  &:target {
    color: $primary;
  }
}

.MarkdownBody h3 {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  @include ms-respond(font-size, 2);
  font-weight: 400;
  color: $primary;
}

.MarkdownBody {
  a,
  a:visited {
    color: $secondary;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  em {
    font-style: normal;
    color: $dh-mute;
  }

  iframe {
    border: 0;
    margin: 0;
    width: 100%;
  }
}
