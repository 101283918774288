@import "./styles/variable";

.extension {
  z-index: 9;
}

.title {
  @include ms-respond(font-size, 2);
  margin: 100px 0 20px;
  text-align: center;
}

p.description {
  @include ms-respond(font-size, 1);
  text-align: center;
  margin: 20px 0;
}

p.otherlinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  a {
    margin-left: 5px;
    @include underline();
    &:hover {
      @include underline-hover();
    }
  }
}

.wapper {
  margin: 10px auto;
  width: 250px;
  background-color: #fff;
  box-shadow: $card-shadow;
  border-radius: 6px;
  color: $grey-dark;
  padding: 0.5rem;
  position: relative;
  a.arrow {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 14px;
    height: 50px;
    padding-left: 60px;
    background: url(./images/icons8-chrome.svg) left/contain no-repeat;
    &.edge {
      background: url(./images/icons8-edge.svg) left/contain no-repeat;
    }
    &.firefox {
      background: url(./images/icons8-firefox.svg) left/contain no-repeat;
      background-size: 46px 46px;
      background-position: left 0.6em center;
    }
    @include stretched-link;

    opacity: 0.85;
    transition: opacity 0.4s ease-in-out, color 0.4s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
}

.preview {
  margin-top: 50px;
  box-shadow: $shadow3;
}

.keysWapper {
  z-index: 9;
  position: relative;
  background-color: $white-duck;
  margin-top: 250px;
  padding: 60px 60px 60px;
}

.devdocsHeader {
  margin: 200px auto 20px;
  @include ms-respond(font-size, 2);
  text-transform: uppercase;
}

.devdocsKeys {
  display: flex;
  flex-wrap: wrap;
  .skeybox {
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    padding: 1em 1em 1em 0.5em;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    position: relative;
    @media (min-width: 1024px) {
      & {
        flex-basis: 20%;
      }
    }

    &:hover,
    &.index {
      background-color: $white;
    }
  }
  .skey {
    display: flex;
    align-items: center;
    line-height: 26.208px; // ms-respond x1.6
    @include stretched-link;
    .skname {
      padding-left: 46px;
      @include ms-respond(font-size, 1);
      text-shadow: 0 0 $dh-text;
      background-position: left 0.5em center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.footer {
  z-index: 9;
  position: relative;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  .links {
    text-align: center;
    a {
      margin: 0 10px;
      font-size: 18px;
      transition: color 0.4s ease-in-out;
    }
  }
  .infos {
    color: $grey-light;
    font-size: 12px;
  }
}
