.rework-item {
  background: #fff;
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  box-shadow: 0 0.05em 0.15em rgba(51, 51, 51, 0.05);
  color: #333;
  margin: 0.4em 0;
  padding: 0.5em;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  @include ms-respond(font-size, 2);

  .tile-icon {
    img {
      border-radius: 0.2em;
      height: 1.4em;
      width: 1.4em;
    }
  }

  .tile-content {
    overflow: hidden;
  }

  .tile-title {
    color: #333;
    font-size: 0.75em;
    font-weight: 700;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    em {
      color: $primary;
      font-style: normal;
    }
  }

  .tile-subtitle {
    color: #b3b2b2;
    font-size: 0.55em;
    font-weight: 400;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    em {
      color: $primary;
      font-style: normal;
      font-weight: 700;
    }
  }

  .tile-highlight {
    background: #eeeef4;
    border-radius: 0.15em;
    color: #c0c0d6;
    display: block;
    font-size: 0.5em;
    font-style: normal;
    height: 0.7em;
    line-height: 0.5em;
    padding: 0.1em 0.1em;
    position: absolute;
    right: 0.4em;
    top: 0.4em;
  }
}

.aa-suggestion.aa-cursor {
  .rework-item {
    box-shadow: 0 0.5em 1em rgba(51, 51, 51, 0.1);
    text-decoration: none;
    transform: translateY(-0.05em);
    border-color: $primary-light;
    border-width: 0.1em;
  }
}

.tile {
  align-content: space-between;
  align-items: flex-start;
  display: flex;
  .tile-action,
  .tile-icon {
    flex: 0 0 auto;
  }
  .tile-subtitle,
  .tile-title {
    line-height: 1.8em;
  }
  .tile-content {
    flex: 1 1 auto;
    &:not(:first-child) {
      padding-left: 0.4em;
    }
    &:not(:last-child) {
      padding-right: 0.4em;
    }
  }
}

.aa-dropdown-menu {
  background: #f7f7f9;
  border-radius: 0.2em;
  box-shadow: 0 0.2em 1em rgba(51, 51, 51, 0.25);
  margin-top: 0.1em;
  max-height: 65vh;
  overflow-y: auto;
  padding: 0.6em 1em;
  width: 100%;
  &::after {
    color: #d9d9d9;
    content: "SEARCH POWERED BY ALGOLIA";
    float: right;
    font-size: 0.6em;
    font-weight: 700;
    line-height: 2em;
  }
  .rework-item {
    max-width: 100%;
  }
}

.rework-title {
  font-size: 0.7em;
  font-weight: 700;
  margin-top: 1em;
  padding-top: 0.5em;
  &:hover .rework-anchor {
    visibility: visible;
  }
  a {
    text-decoration: none;
  }
  a:focus,
  a:hover {
    color: #09006d;
  }
}

.rework-list-container {
  margin-top: 0.5em !important;
  flex-wrap: wrap;
  .column {
    flex-basis: auto;
    flex-grow: 0;
  }
}
