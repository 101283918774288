@import '../styles/variable';

.rework {
  opacity: 0.4;
  margin: 0 20px;
  @include ms-respond(font-size, 2);
  &.loaddone {
    opacity: 1;
    transition: opacity 100ms linear 100ms;
  }
}

p.reference {
  margin: 0 20px 1rem;
  a {
    background: url(../../public/keys/rework.png) left center/contain no-repeat;
    padding-left: 20px;
    font-size: 12px;
  }
}
