@import "../styles/variable";

.expendWidth {
  max-width: 100% !important;
}

.searchWapper {
  background: #fff;
  border-radius: 6px;
  transition: box-shadow 0.3s;
  box-shadow: $shadow_ionic;
  &.focus {
    box-shadow: $shadow_ionic_focus;
  }
  width: 100%;
  position: absolute;
  top: 130px;
  // margin-bottom: 20px;
  z-index: 8;

  *::-webkit-scrollbar-track {
    background: $white;
    border: 1px solid $white;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  background: #fff;
  position: sticky;
  top: 57px;
  z-index: 10;
  border-bottom: solid 1px $white-ter;
  a {
    background-color: $white-ter;
    color: $grey-light;
    padding: 2px 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-left: 5px;
    &.current {
      color: $primary;
    }
  }
}

.searchInput {
  display: flex;
  height: 64px;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  padding: 0 16px;
  position: sticky;
  top: -6px;
  z-index: 10;
  .input {
    @include ms-respond(font-size, 2);
    padding: 16px 0;
    margin-right: 10px;
    height: 64px;
    background: transparent;
    border: 0;
    flex: 1 1 auto;
    color: $dh-head;
    min-width: 48px;
    caret-color: $dh-mute;

    &::placeholder {
      color: $dh-mute;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      // https://stackoverflow.com/a/14205976/346701
      box-shadow: 0 0 0 50px white inset !important;
      -webkit-text-fill-color: $dh-mute !important;
    }
    &:focus {
      outline: 0;
    }
  }

  & > .prefix {
    height: 32px;
    line-height: 32px;
    @include ms-respond(font-size, 1);
    display: flex;
    align-items: center;
    user-select: none;
    margin-right: 8px;
    border-radius: 3px;
    color: rgba($dh-mute, 0.75);
    background: rgba($dh-body, 0.5);
    box-shadow: 0 1px 1px rgba($dh-mute, 0.25);
    cursor: pointer;
    font-family: $font-mono;
    padding-right: 5px;
    &:hover {
      background: rgba($dh-body, 0.8);
      box-shadow: 0 1px 1px rgba($dh-mute, 0.4);
    }
    &:active {
      box-shadow: none;
    }
    &.displayKeys {
      color: $white;
      background: rgba($dh-mute, 0.5);
      box-shadow: 0 1px 1px rgba($dh-text, 0.25);
    }

    span.name {
      font-family: "Roboto Slab";
      padding: 0 3px 0 8px;
      @media (max-width: 768px) {
        & {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 40px;
        }
      }
    }
    span.model {
      padding: 0 3px;
      display: flex;
      align-items: center;
      height: 32px;
      &.active {
        color: $primary;
      }
      &:hover {
        color: $black-ter;
      }
      svg.searchModel {
        width: 15px;
      }
    }
  }

  & > .sep {
    color: rgba($dh-mute, 0.5);
    @include ms-respond(font-size, 2);
    margin: 0 6px;
    font-family: "Roboto Slab";
  }

  // Hide prefix in mobiel
  // @media (max-width: 768px) {
  //   & > .prefix,
  //   & > .sep {
  //     display: none;
  //   }

  //   .input {
  //     padding-left: 16px;
  //   }
  // }

  // Icon
  & > svg.sicon {
    color: rgba($dh-mute, 0.5);
    font-size: 20px;
    flex: 0 0 32px;
    order: 2;
    margin-left: 10px;
    cursor: pointer;
  }

  &.-small {
    & {
      height: 48px;
    }

    .input {
      padding: 8px;
      height: 48px;
      padding-left: 0;
    }

    & > .prefix,
    & > .sep,
    .input {
      @include ms-respond(font-size, 1);
    }

    &::before {
      flex: 0 0 48px;
      width: 48px;
      line-height: 48px;
      background-size: 24px 24px;
      background-position: center center;
    }
  }

  .devdocs_select {
    min-width: 230px;
    & > div {
      border-color: $grey-lighter;
    }
    .selectItem {
      padding-right: 72px;
      background-position: right center !important;
      background-size: contain !important;
      background-repeat: no-repeat;
      display: inline-block;
      width: 100% !important;
      i {
        color: $grey-lighter;
        margin-left: 5px;
        font-style: normal;
      }
    }
  }

  .inphotkey {
    margin: 0 20px;
    color: $grey-light;
    font-size: 12px;
    span {
      background-color: #f5f5f5;
      border: 1px solid #e5e8ed;
      border-radius: 4px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
      padding: 2px 5px;
      &.keya {
        margin-left: 6px;
      }
    }
  }

  &:focus-within {
    .inphotkey {
      display: none;
    }
  }
}

.docsearch {
  flex-grow: 1;
  margin-right: 10px;
  :global(.algolia-autocomplete) {
    width: 100%;
    & > input[type="search"] {
      width: 100%;
    }
  }
}

.suggeste {
  display: block !important;
  top: -15px;
  a {
    @include ms-respond(font-size, 1);
  }
  span.stars,
  span.publisher {
    margin-left: 20px;
    color: $grey-light;
  }
  span.version {
    float: right;
  }
  em {
    font-weight: 700;
    font-style: normal;
  }
  .sgactive,
  .sgitem:hover {
    background: $primary;
    color: $grey-lighter;
    cursor: pointer;
    span.stars,
    span.publisher {
      color: $white-ter;
    }
    a,
    span.version {
      color: $white;
    }
  }
  a.npms,
  a.algolia {
    font-size: 12px;
    margin: 0 1rem;
    svg {
      margin-right: 6px;
    }
  }
  a.bundlephobia {
    background: url(../../public/keys/bundlephobia.svg) left center/contain no-repeat;
    padding-left: 20px;
    font-size: 12px;
    margin: 0 1rem;
  }
}

.skgroup,
.searchedKeys {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  border-top: solid 1px $white-ter;
  margin-top: 25px;
  // padding-top: 5px;
  &:not(:last-child) {
    padding-bottom: 25px;
  }
  &.searchedKeys {
    flex-direction: column;
    .skeybox {
      width: 100%;
    }
  }
  &.pinnned {
    margin-top: 0;
    border-top: none;
  }
  .skeybox {
    font-family: $font-sans-doc;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    padding: 1em 1em 1em 0.5em;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    @media (min-width: 768px) {
      & {
        flex-basis: 50%;
      }
    }
    @media (min-width: 1024px) {
      & {
        flex-basis: 33.333%;
      }
    }

    svg.thumbtack {
      display: none;
      color: $grey-light;
      position: relative;
      top: 1px;
      &:hover {
        color: $primary;
      }
      &.pin {
        transform: rotate(90deg);
      }
    }

    // a.kicon,
    // i.kicon {
    //   display: none;
    // }

    .skey {
      display: flex;
      align-items: center;
      line-height: 26.208px; // ms-respond x1.6
      .skname {
        padding-left: 46px;
        @include ms-respond(font-size, 1);
        text-shadow: 0 0 $dh-text;
        background-position: left 0.5em center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      svg.usage {
        color: $primary-light;
      }
      .shortkeys {
        margin: 0 10px;
        color: $grey-light;
        .plus {
          margin-left: 6px;
          display: none;
        }
        .tab {
          margin-left: 6px;
          background-color: #f5f5f5;
          border: 1px solid #e5e8ed;
          border-radius: 4px;
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
          padding: 2px 5px;
          display: none;
        }
      }
    }

    &:hover,
    &.index {
      background-color: $white-duck;
      // a.kicon,
      // i.kicon,
      svg.thumbtack {
        display: inline-block;
      }
      .shortkeys {
        .plus,
        .tab {
          display: inline;
        }
      }
      svg.usage {
        display: none;
      }
    }

    &.operation {
      position: relative; // for stretched-link
      padding: 1em 1em 1em 0.5em;
      &:hover {
        background-color: transparent;
        padding: 0.7em 0.7em 0.7em 0.2em;
        border: 0.3em dashed $grey-lighter;
      }
      .skname {
        @include stretched-link;
        color: $grey-light;
        &.submit_res {
          background-image: url(../images/icons8-forms.svg);
        }
        &.newtab {
          background-image: url(../images/icons8-add-tab.png);
        }
        &.chrome {
          background-image: url(../images/icons8-chrome.svg);
        }
        &.firefox {
          background-image: url(../images/icons8-firefox.svg);
          background-size: 46px 46px;
          background-position: left 0.6em center;
        }
        &.edge {
          background-image: url(../images/icons8-edge.svg);
        }
      }
    }
  }

  .kdesc {
    position: absolute;
    top: -$fontsize * 1.6 - 4px;
    left: 0;
    background-color: $white-ter;
    color: $grey-light;
    padding: 2px 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

a.kicon,
i.kicon {
  @include ms-respond(font-size, 1);
  color: $grey-light;
  margin-right: 5px;
  cursor: pointer;
  &:hover,
  &.active {
    color: $primary;
  }
}

.searchResult {
  padding: 0 20px;
  .result {
    padding: 0 0 15px;
  }
  .header {
    @include ms-respond(font-size, 2);
    text-shadow: 0 0 $dh-text;
  }
  .external {
    color: #069025;
    font-size: 12px;
  }
  .content {
    word-wrap: break-word;
    color: $dh-text;
    @include ms-respond(font-size, 1);
  }
  .highlighter {
    font-weight: 700;
    background: #f8f8f8;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
  }

  .infos {
    display: flex;
    color: $grey;
    span {
      height: 18px;
    }
    a.github {
      color: $grey;
      margin-right: 10px;
      &:hover {
        color: $black;
      }
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  color: $pink;
  @include ms-respond(font-size, 2);
}

.notFound {
  // background: url("../images/not_found.png") center/20% no-repeat;
  text-align: center;
  height: 200px;
}

div.closer {
  position: absolute;
  top: 0;
  right: -40px;
  width: 40px;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  &:hover {
    opacity: 1;
    background: linear-gradient(to left, $dh-body, rgba(0, 0, 0, 0.1));
  }
  & > a {
    margin-top: 20px;
    margin-left: 10px;
  }
}

:global(.algolia-autocomplete.algolia-autocomplete-right .ds-dropdown-menu) {
  right: inherit !important;
  left: 0 !important;
}
:global(.algolia-autocomplete.algolia-autocomplete-right .ds-dropdown-menu:before) {
  left: 48px;
}

/* Use CSS grid to create a two-column layout */



// @media (prefers-color-scheme: dark) {
//   .searchWapper {
//     background: #000;
//     box-shadow: $shadow_ionic_invert;
//     &.focus {
//       box-shadow: $shadow_ionic_focus_invert;
//     }
//   }
//   .searchInput {
//     .input {
//       color: invert($dh-head);
//       caret-color: invert($dh-mute);
//       &::placeholder {
//         color: invert($dh-mute);
//       }
//       &:-webkit-autofill,
//       &:-webkit-autofill:hover,
//       &:-webkit-autofill:focus,
//       &:-webkit-autofill:active  {
//           // https://stackoverflow.com/a/14205976/346701
//           box-shadow: 0 0 0 50px black inset !important;
//           -webkit-text-fill-color: invert($dh-mute) !important;
//       }
//     }
//     & > .prefix {
//       color: rgba(invert($dh-mute), 0.5);
//       background: rgba(invert($dh-body), 0.5);
//       margin: 0 0 0 16px;
//       box-shadow: 0 1px 1px rgba(invert($dh-mute), 0.25);
//     }

//     & > .sep {
//       color: rgba(invert($dh-mute), 0.5);
//     }
//     & > i.sicon {
//       color: rgba(invert($dh-mute), 0.5);
//     }
//   }
//   .searchResult {
//     .header {
//       text-shadow: none;
//     }
//     .external {
//       color: invert(#069025);
//     }
//     .content {
//       color: invert($dh-text);
//     }
//   }
//   .error {
//     color: invert($pink);
//   }
// }
