@charset "utf-8";

// https://github.com/modularscale/modularscale-sass
@import "~modularscale-sass/stylesheets/modularscale";
$modularscale: (
  base: 14px,
  ratio: 1.2,
  480px: (
    base: 13px,
    ratio: 1.15,
  ),
  768px: (
    base: 14px,
    ratio: 1.16,
  ),
  1280px: (
    base: 14px,
    ratio: 1.17,
  ),
);

$primary: #745fb5;
$primary-light: lighten($primary, 15%);
$primary-dark: darken($primary, 15%);
$primary2: #9a6dbb;
$primary3: #5f4aa1;

$secondary: #26648e;
$secondary2: adjust-color($secondary, $lightness: 16%, $hue: -20deg);
$tertiary: #14af83;

$black: hsl(0, 0%, 4%) !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;

$grey-darker: hsl(0, 0%, 21%) !default;
$grey-dark: hsl(0, 0%, 29%) !default;
$grey: hsl(0, 0%, 48%) !default;
$grey2: hsl(0, 0%, 60%) !default;
$grey-light: hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;
$grey-lightest: hsl(0, 0%, 93%) !default;

$white-terer: hsl(0, 0%, 95%) !default;
$white-ter: hsl(0, 0%, 96%) !default;
$white-duck: hsl(0, 0%, 97%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: hsl(0, 0%, 100%) !default;

$dh-body: #f1f3f5;
$dh-head: #111;
$dh-text: #345;
$dh-mute: #678;
$dh-gray-bg: mix($dh-body, white, 50%);

$line: rgba($dh-mute, 0.05);
$line-dark: rgba($dh-mute, 0.2);

$naka: rgb(63, 63, 63);
$red: rgb(221, 56, 35);
$orange: rgb(204, 102, 0);
$yellow: rgb(255, 246, 168);
$green: rgb(41, 132, 90);
$blue: rgb(24, 96, 192);
$pink: #fa7c91;

$primary-gradient: linear-gradient(5deg, $primary, adjust-color($primary, $lightness: 2%, $hue: 16deg));

$fontsize: 14px;
$font-sans: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$font-sans-doc: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Arial,
  sans-serif;
// $font-mono: Consolas, Menlo, "Liberation Mono", "Ubuntu Mono", monospace;
$font-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, "Ubuntu Mono", monospace;

$card-shadow: 0 0.5em 1em -0.125em rgba($black, 0.1), 0 0px 0 1px rgba($black, 0.02);

$shadow2: 0 1px 1px rgba($dh-mute, 0.55);

$shadow3: 0 6px 8px rgba($dh-mute, 0.03), 0 1px 2px rgba($dh-mute, 0.3);

$shadow3_invert: 0 6px 8px rgba(invert($dh-mute), 0.03), 0 1px 2px rgba(invert($dh-mute), 0.3);

$shadow6: 0 6px 8px rgba($dh-mute, 0.03), 0 1px 2px rgba($dh-mute, 0.3), 0 8px 12px rgba($secondary2, 0.1);

$shadow_ionic: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
$shadow_ionic_focus: 0 6px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);

$shadow_ionic_invert: 0 3px 6px 0 rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.08);
$shadow_ionic_focus_invert: 0 6px 12px 0 rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.08);

@mixin ion-base {
  display: inline-block;
  vertical-align: middle;
}

@mixin text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// Requires inline-block or block
@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// https://getbootstrap.com/docs/4.3/utilities/stretched-link/
// https://github.com/twbs/bootstrap/blob/master/scss/utilities/_stretched-link.scss
@mixin stretched-link() {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}

@mixin split-line($prefix, $color) {
  position: relative;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    transform-origin: center;
    box-sizing: border-box;
    pointer-events: none;
    left: $prefix;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: 1px solid $color;
  }
}

@mixin active-color() {
  transition: background-color 0.3s;
  &:active {
    background-color: #f0f0f0;
  }
}

// https://www.figma.com/
@mixin underline($color: $dh-mute) {
  display: inline-block;
  &:after {
    color: $color;
    content: "";
    height: 3px;
    opacity: 0;
    transform: translateY(3px) scaleY(0);
    transition: transform 250ms, opacity 250ms;
    transform-origin: center left;
  }
}

@mixin underline-hover($bottom: -3px, $height: 3px) {
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $bottom;
    height: $height;
    background: currentColor;
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}

$code-size: 0.96em;
$code-line-height: 1.5;
$column: 400px;
$area-width: $column * 3 + 32px;

@mixin section-gutter($property, $multiplier: 1) {
  #{$property}: 16px * $multiplier;

  @media (max-width: $area-width + 32px) {
    #{$property}: 8px * $multiplier;
  }
}

@mixin heading-style {
  margin: 0;
  padding: 0;
  margin-bottom: 16px + 8px;
  margin-top: 64px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 8px;
    margin-top: 32px;
  }

  @media (max-width: 480px) {
    margin-bottom: 8px;
    margin-top: 32px;
  }
}
