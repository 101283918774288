@import "../styles/variable";

.hamburger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  outline: none;
  z-index: 10;
  // :global(&.is-active) {
  //   right: 0%;
  // }
}

.drawer {
  overflow: auto;
  &::-webkit-scrollbar-track {
    background: $white;
    border: 1px solid $white;
  }

  width: 380px;
  position: fixed;
  right: 0;
  top: 20px;
  bottom: 20px;
  background: #fff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 9;
  transition: transform 0.5s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  transform: translateX(100%);
  &.active {
    box-shadow: $card-shadow;
    transform: translateX(0%);
  }

  .jacket {
    padding: 20px;
  }
  .skirt {
    padding: 20px 20px 20px;
    background-color: #fafafa;
    border-top: solid 1px $white-bis;
    border-bottom-left-radius: 6px;
    a.navlink:hover {
      background-color: #fafafa;
    }
  }

  .slogon,
  .principles {
    color: $grey-light;
    font-size: 12px;
  }
  .slogon {
    font-size: 13px;
    margin-top: 10px;
  }

  a.navlink {
    padding: 1.4em 1.5em 1.4em calc(1em + 50px);
    background-position: left 0.5em center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    h3 {
      @include underline();
    }
    &:hover {
      background-color: $white-duck;
      h3 {
        @include underline-hover();
      }
    }
    & > span {
      display: block;
      color: $grey-light;
      margin-top: 4px;
    }
    &.chrome {
      background-image: url(../images/icons8-chrome.svg);
    }
    &.newtab {
      background-image: url(../images/icons8-add-tab.png);
      background-size: 42px 42px;
      background-position: left 0.7em center;
    }
    &.pwa {
      background-image: url(../images/pwa.png);
      background-size: 44px 44px;
      background-position: left 0.8em center;
    }
    &.edge {
      background-image: url(../images/icons8-edge.svg);
    }
    &.firefox {
      background-image: url(../images/icons8-firefox.svg);
      background-size: 46px 46px;
      background-position: left 0.6em center;
    }
    &.privacy {
      background-image: url(../images/icons8-fingerprint-100.png);
    }
    &.shortcut {
      background-image: url(../images/icons8-keyboard-100.png);
    }
    &.discord {
      background: #fff url(../images/discord.svg) left 0.6em center/30px 30px no-repeat;
      padding: 0.8em 0.8em 0.8em calc(1.2em + 30px);
    }
    &.telegram {
      background: #fff url(../images/telegram.svg) left 0.9em center/22px 22px no-repeat;
      padding: 0.8em 0.8em 0.8em calc(1.2em + 30px);
    }
    &.wechat {
      background: #fff url(../images/wechat.svg) left 0.9em center/22px 22px no-repeat;
      padding: 0.8em 0.8em 0.8em calc(1.2em + 30px);
    }
    &.form {
      background: #fff url(../images/icons8-forms.svg) left 0.7em center/26px 26px no-repeat;
      padding: 0.8em 0.8em 0.8em calc(1.2em + 30px);
    }
    &.footicon {
      font-size: 18px;
      padding: 0.3em 0.5em;
    }
    // &.producthunt {
    //   background: #fafafa url(../images/producthunt.svg) center/22px 22px no-repeat;
    //   padding: 0.5em 0.5em 0.5em calc(0.5em + 22px);
    //   // padding: 0.8em 0.8em 0.8em calc(1.2em + 30px);
    // }
    // &.twitter {
    //   background: #fafafa url(../images/twitter.svg) center/22px 22px no-repeat;
    //   padding: 0.5em 0.5em 0.5em calc(0.5em + 22px);
    // }
  }

  li.loginItem {
    display: flex;
    justify-content: space-between;
    & > a.navlink {
      flex: 1 50%;
      &.github {
        background-image: url(../images/icons8-github.svg);
      }
      &.google {
        background-image: url(../images/icons8-google.svg);
      }
    }
  }

  li.profile {
    display: block;
    & > a.navlink {
      padding: 1.4em 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div.profileInfo {
        display: flex;
        align-items: center;
      }
      svg {
        font-size: 1.3em;
      }
    }
    :global(a.dropdown-item) {
      font-size: 1em;
      &.disable {
        color: $grey-light;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }
      &.logout {
        color: $red;
      }
    }

    .shareinput {
      padding: 0.5em 0.75em 0;
    }
  }

  // div.share {
  //   display: inline-block;
  //   .footicon {
  //     font-size: 17px;
  //   }
  //   .shareinput {
  //     padding: 0;
  //   }
  // }

  .field {
    margin: 20px 0;
  }
}

.helpSignPart {
  background-color: $black-bis;
  border-radius: 4px;
  color: #fff;
  line-height: 17px;
  margin: 0;
  padding: 20px;
  z-index: 99;
  .title {
    border-bottom: 1px solid $grey-dark;
    color: $grey;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .hint {
    color: $grey;
    float: right;
  }
  div.section {
    margin-bottom: 17px;
    margin-top: 0;
  }
  div.section .label {
    color: $grey;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  .shortcut {
    color: $tertiary;
    display: inline-block;
    font-weight: 700;
    font-weight: normal;
    padding-right: 6px;
    width: 80px;
    font-family: $font-mono;
  }
  .description {
    font-weight: normal;
  }
}

.wechatqr {
  width: 360px !important;
}

.profileMenuMask {
  z-index: 19;
}
