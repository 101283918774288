@import "../styles/variable";

.cheatsheets {
  padding: 10px 18px;
  margin-bottom: 10px;
  background-color: $dh-body;
  :global(.single) {
    max-width: 800px;
    margin: 0 auto;
    h3 {
      font-size: 1.6rem;
    }
    code {
      font-size: 1rem;
    }
  }
}

p.devhints {
  margin: 0 20px 1rem;
  a {
    background: url(../../public/keys/devhints.png) left center/contain no-repeat;
    padding-left: 20px;
    font-size: 12px;
  }
}
